import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermineMargin from "../../components/termine-margin"
import ContentHeader from "../../components/unsereschule/contentHeader"
import Submenu from "../../components/unsereschule/submenu"
import { graphql } from "gatsby"
import Stellenausschreibung_Praktikantin from "../../components/unsereschule/stellenausschreibung_praktikantin"

export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
  }
`

const AnsprechpartnerPage = ({data}) => (
  <Layout>
    <SEO title="Karriere"/>

    <ContentHeader headline="UNSERE SCHULE" subline={data.allSanitySiteSettings.nodes[0].motto}/>
    <section id="content_2_schule">

      <Submenu/>

      <article id="con_2_rechts">
        <Stellenausschreibung_Praktikantin />
      </article>

      <TermineMargin/>
    </section>
  </Layout>
)

export default AnsprechpartnerPage

import React from "react"

import file from "../../assets/2024-05-16_Stellenausschreibung_Anerkennungspraktikantin.pdf"
import betreuungsAntrag from "../../assets/2021-06-02_AnmeldungBetreuung.pdf"
import { GrDocumentPdf } from "react-icons/gr"

const Stellenausschreibung_Praktikantin = () => (
  <section id="content_1">

    <h3 className="small_sub"><span
      style={{ color: "#007c34" }}>Stellenausschreibung<br/>Praktikantin / Praktikant im Anerkennungsjahr</span>
    </h3>

    <p className="text">
      Für die Arbeit im Bereich <strong>„Pädagogik der Kindheit“</strong> suchen wir
      zum <strong>01.08.2024</strong> eine Praktikantin / einen Praktikanten im Anerkennungsjahr.
      Voraussetzung ist ein Abschluss im Studiengang Kindheitspädagogik.
    </p>
    <p className="text" style={{ marginTop: "20px" }}>
      Das Anerkennungsjahr kann ausschließlich in Vollzeit für ein Jahr absolviert werden. Die wöchentliche Arbeitszeit
      beträgt 39,83 Stunden.
      Für die Bezahlung gelten die Regelungen nach dem Tarif Prakt-L.
    </p>
    <p className="text" style={{ marginTop: "20px" }}>
      Die Grundschule Löhne-Bahnhof ist eine Grundschule mit Offenem Ganztag. Zurzeit werden ca. 260 Schülerinnen und
      Schüler in 12 Klassen unterrichtet.
      Außerdem werden neu zugewanderte Kinder neben der Teilnahme am Regelunterricht stundenweise in zwei
      Internationalen Gruppen beim Erwerb der deutschen Sprache gefördert.
      Etwa 66 % der Kinder leben in Familien mit Migrationshintergrund.
      Wir sind ein Team aus Lehrerinnen und Lehrern, einer sozialpädagogischen Fachkraft, einer Schulsozialarbeiterin
      und den Erzieherinnen aus der OGS.
    </p>
    <p className="text" style={{ marginTop: "20px" }}>
      Wir bieten eine qualifizierte Praxisanleitung durch unsere sozialpädagogische Fachkraft sowie Einblicke in
      verschiedene Tätigkeitsbereiche.
    </p>

    <p className="text" style={{ marginTop: "20px" }}><strong>Ihre Tätigkeiten:</strong><br/>
      <ul>
        <li>Unterstützung der Lehrkräfte im schulischen Unterricht</li>
        <li>Begleitung bei der Beratung von Eltern und Kindern</li>
        <li>Mitwirkung bei speziellen Fördermaßnahmen verschiedener Wahrnehmungsbereiche</li>
        <li>Mitwirkung bei der Durchführung von Förderdiagnostiken sowie der Erstellung von Förderplänen</li>
        <li>Vorbereitung und Durchführung von Einzelfallhilfe und Gruppenangeboten (soziales Lernen), speziell in
          Konflikt- und Krisensituationen
        </li>
        <li>Vorbereitung und Durchführung eigener Projekte</li>
        <li>Netzwerkarbeit</li>
        <li>enge Mitarbeit im Kollegium und im pädagogischen Team der OGS</li>
        <li>Mitarbeit im OGS-Alltag</li>
        <li>Einsatz zur Ferienbetreuung in der OGS</li>
      </ul>
    </p>

    <p className="text" style={{ marginTop: "20px" }}>
      <strong>Unsere Anforderungen:</strong><br/>
      Wir erwarten Erfahrung und Freude bei der Arbeit mit Menschen, insbesondere mit Kindern.
      Weiterhin wünschen wir uns eine wertschätzende Grundhaltung, Offenheit, Teamfähigkeit, Konflikt- und
      Kritikfähigkeit sowie ein hohes Maß an Flexibilität.
    </p>

    <p className="text" style={{ marginTop: "20px" }}>
      <strong>Hinweis:</strong><br/>
      Die Stelle wird unter Vorbehalt der endgültigen Zuweisung ausgeschrieben und geht mit einer
      Rücktrittsmöglichkeit
      bis zur entsprechenden Zuweisung einher.
    </p>

    <p className="text" style={{ marginTop: "20px" }}>
      Wir freuen uns auf Ihre aussagekräftige Bewerbung bis zum <strong>13.06.2024</strong> an:<br/><br/>
      Ev. Grundschule Löhne-Bahnhof<br/>
      Schulleiterin Frau U. Lohöfener<br/>
      Königstr. 47<br/>
      32584 Löhne<br/>
      Tel.: 05732-3301<br/>
      E-Mail: <a href="mailto:gs.loehne-bahnhof@schulen.loehne.de">gs.loehne-bahnhof@schulen.loehne.de</a>
    </p>

    <div style={{marginTop: "40px"}}>
      <a className="mehr_gruen text" href={file} target="_blank" download rel="noopener noreferrer"><GrDocumentPdf
        style={{
          width: "1.5em",
          height: "1.5em",
          marginRight: "0.5em",
          verticalAlign: "bottom",
        }}/><strong>Stellenausschreibung
        Praktikantin / Praktikant im Anerkennungsjahr</strong></a><br/>
    </div>
  </section>
)

export default Stellenausschreibung_Praktikantin

import React from "react"
import { Link } from "gatsby"

const Submenu = () => (

  <nav className="nav_schule">
    <ul>
      <li><Link to="/unsereschule/ansprechpartner" className="menu">Ansprechpartner</Link></li>
      <li><Link to="/unsereschule/kollegium" className="menu">Das Kollegium</Link></li>
      <li><Link to="/unsereschule/karriere" className="menu">Karriere</Link></li>
    </ul>
    <div className="shadow_links"></div>
  </nav>
)

export default Submenu
